<template>
  <div class="companyCulture">
    <div class="cc-content">
      <div v-for="(item, index) in companyCultureList" :key="index" class="cc-pic">
        <img style="width: 100%; height: 100%" :src="item.img" fit="cover" />
        <div class="cc-text">
          <h5>{{ item.title }}</h5>
          <i></i>
          <p>{{ item.jsonText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  name: "companyCulture",
  data() {
    return {
      companyCultureList: [],
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("https://www.claybbt.com/queryTitleId", {
          params: {
            language: lang,
            titleId: this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data.pageDatas) {
              this.companyCultureList = res.data.data.pageDatas;
            }
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  computed: {},
  filters: {},
};
</script>

<style lang="less" scoped>
.companyCulture {
  display: flex;
  justify-content: center;
  .cc-content {
    display: flex;
    .cc-pic {
      position: relative;
      width: 290px;
      height: 417px;
      margin: 0 50px;
      text-align: center;
      cursor: pointer;
      .cc-text {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin-top: 150px;
        h5 {
          font-size: 20px;
          color: #fff;
        }
        i {
          display: block;
          width: 21px;
          height: 1px;
          background: #d80930;
          margin: 13px auto 10px;
        }
        p {
          font-size: 15px;
          line-height: 27px;
          color: #fff;
          padding: 0 20px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
